import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
      <div className="mainWrapper">
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          <Link to="/" alt="go back to the homepage">Home</Link>
      </div>
  </Layout>
)

export default NotFoundPage
